import { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import VuiBox from "components/VuiBox";
import { Card, Grid } from "@mui/material";
import VuiTypography from "components/VuiTypography";
import Loading from "components/shared-components/Loading";
import InvitationService from "services/InvitationService";
import { enqueueSnackbar } from "notistack";
import { useApi } from "hooks/useAPI";
import { useParams } from "react-router-dom";

interface InvitationParams {
  invitationToken: string;
}

const InvitationPage = () => {
  const { getToken } = useApi();
  const { isAuthenticated, isLoading, login } = useKindeAuth();
  const location = useLocation();
  const history = useHistory();
  const { invitationToken } = useParams<InvitationParams>();

  const handleLogin = () => {
    localStorage.setItem("nextUrl", location.pathname + location.search);
    login({});
  };

  const handleAcceptInvitation = async () => {
    const token = getToken ? await getToken() : undefined;
    try {
      const invitationResult = await InvitationService.acceptInvitation({ invitationToken }, token);
      enqueueSnackbar("Invitation accepted successfully! will redirect to the dashboard shortly", { variant: "success" });
      setTimeout(() => {
        history.push("/app");
      }, 3000);
    } catch (error: any) {
      console.log(error);
      enqueueSnackbar(error.response.data.message || "Failed to accept invitation", { variant: "error" });
    }
  };

  if (isLoading) {
    return <Loading cover="page" />;
  }

  return (
    <VuiBox py={3}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} md={6}>
          <Card>
            <VuiBox p={3}>
              <VuiTypography
                variant="h3"
                color="white"
                fontWeight="medium"
                mb={3}
              >
                Invitation Page
              </VuiTypography>
              {!isAuthenticated ? (
                <>
                  <VuiTypography
                    variant="body1"
                    color="white"
                    fontWeight="regular"
                    mb={3}
                  >
                    Please log in or create an account to accept the invitation.
                  </VuiTypography>
                  <VuiBox
                    component="button"
                    onClick={handleLogin}
                    bgcolor="info.main"
                    p={2}
                    borderRadius="lg"
                    sx={{ cursor: "pointer", border: "none" }}
                  >
                    <VuiTypography color="white" fontWeight="medium">
                      Login
                    </VuiTypography>
                  </VuiBox>
                </>
              ) : (
                <>
                  <VuiTypography
                    variant="body1"
                    color="white"
                    fontWeight="regular"
                    mb={3}
                  >
                    You've been invited to join. Click below to accept the invitation.
                  </VuiTypography>
                  <VuiBox
                    component="button"
                    onClick={handleAcceptInvitation}
                    bgcolor="success.main"
                    p={2}
                    borderRadius="lg"
                    sx={{ cursor: "pointer", border: "none" }}
                  >
                    <VuiTypography color="white" fontWeight="medium">
                      Accept Invitation
                    </VuiTypography>
                  </VuiBox>
                </>
              )}
            </VuiBox>
          </Card>
        </Grid>
      </Grid>
    </VuiBox>
  );
};

export default InvitationPage; 