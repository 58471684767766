import { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";

import AvatarStatus from "components/shared-components/AvatarStatus";
import { Stack, Typography } from "@mui/material";
import { LinearProgress } from "@mui/material";

import { pageCommonProps, selectedMerchant as setSelectedMerchant } from "redux/actions";
import Warning from "./Warning";
import moment from "moment";
import FacebookConnect from "integrations/facebook";
import GoogleConnect from "integrations/google";
import { gapi } from "gapi-script";
import { env } from "configs/EnvironmentConfig";
import { ShopifyStoreSettingsModal } from "./ShopifySettingsModal";
import { FacebookSettingsModal } from "./FacebookSettingsModal";
import { GoogleSettingsModal } from "./GoogleSettingsModal";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import VuiBox from "components/VuiBox";
import { Card, Grid } from "@mui/material";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import { AttachMoney, TimerOutlined } from "@mui/icons-material";
import ShopifyCollectionStatus from "./ShopifyCollectionStatus";
import { useApi } from "hooks/useAPI";
import { InviteUserModal } from "./InviteUserModal";
import AccessDeniedCard from "components/shared-components/AccessDeniedCard";
import  MerchantService  from "services/MerchantService";
import { useSnackbar } from "notistack";
const { GOOGLE_CLIENT_ID, GOOGLE_SCOPES } = env;

enum ActiveModal {
  Shopify,
  Facebook,
  Google,
  InviteUser
}

function StoreDetails({ pageCommonProps }: any) {
  const { getToken } = useApi();
  const selectedMer = useSelector(
    (state: any) => state.merchants.selectedMerchant
  );
  const userRole = useSelector((state: any) => state.merchants.selectedMerchant.roles?.name);
  const isOwner = userRole === "Owner";
  const [activeModal, setActiveModal] = useState<ActiveModal | undefined>();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    document.title = "Store Details";
    (async () => {
      pageCommonProps("storeDetails");
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // a script that's running in the header always, necessary function
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const startGoogle = async () => {
    await gapi.client.init({
      clientId: GOOGLE_CLIENT_ID,
      scope: GOOGLE_SCOPES,
    });
    await gapi.load("client:auth2", startGoogle);
  };

  const Demo = () => {
    const startOfDate = moment(selectedMer?.facebookAccessTokenExpiry?.start),
      endDate = moment(selectedMer?.facebookAccessTokenExpiry?.end),
      todayDate = moment();

    const daysDifference = moment(endDate).diff(startOfDate, "days");
    const difference = todayDate.diff(startOfDate, "days");

    const result = Math.round((difference / daysDifference) * 100);

    return (
      <VuiBox>
        <LinearProgress
          variant="determinate"
          value={result}
          sx={{
            background: "rgba(16, 142, 233, 0.3)",
            "& .MuiLinearProgress-bar": {
              width: `${result}%`,
              background: "linear-gradient(90deg, #108ee9, #87d068)",
            },
          }}
        />
        <VuiTypography variant="caption" color="text" mt={1} display="block">
          {`${result}% used`}
        </VuiTypography>
      </VuiBox>
    );
  };

  const getLogo = (props: any) => {
    if (props === "shopify") {
      return "/img/shopify.png";
    }
    if (props === "facebook") {
      return "/img/facebook.png";
    }
    if (props === "google") {
      return "/img/google.png";
    } else {
      return "/img/daily-mentor-app.png";
    }
  };

  const ProfileInfo = () => (
    <Card>
      <Grid container>
        <Grid item xs={12}>
          <VuiBox p={3}>
            <VuiTypography variant="h2" color="white" fontWeight="bold">
              {selectedMer.name}
            </VuiTypography>

            <Grid container spacing={4} mt={2}>
              <Grid item xs={12} md={4}>
                <VuiBox display="flex" alignItems="center">
                  <TimerOutlined sx={{ color: "white !important" }} />
                  <VuiBox ml={2}>
                    <VuiTypography
                      variant="button"
                      color="text"
                      fontWeight="regular"
                    >
                      Time Zone:
                    </VuiTypography>
                    <VuiTypography
                      variant="button"
                      color="white"
                      fontWeight="medium"
                      ml={1}
                    >
                      {selectedMer.shopifyTimeZone}
                    </VuiTypography>
                  </VuiBox>
                </VuiBox>
              </Grid>

              <Grid item xs={12} md={6}>
                <VuiBox display="flex" alignItems="center">
                  <AttachMoney sx={{ color: "white !important" }} />
                  <VuiBox ml={2}>
                    <VuiTypography
                      variant="button"
                      color="text"
                      fontWeight="regular"
                    >
                      Currency:
                    </VuiTypography>
                    <VuiTypography
                      variant="button"
                      color="white"
                      fontWeight="medium"
                      ml={1}
                    >
                      {selectedMer.currency}
                    </VuiTypography>
                  </VuiBox>
                </VuiBox>
              </Grid>
            </Grid>
          </VuiBox>
        </Grid>
      </Grid>
    </Card>
  );

  const StoreConnections = () => (
    <Grid container gap={2}>
      {/* Shopify Connection */}
      <Grid item xs={12} md={6} lg={3}>
        <Card>
          <VuiBox p={2}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12}>
                <AvatarStatus
                  src={getLogo("shopify")}
                  name="Shopify Connection"
                />
              </Grid>
              <Grid item xs={6}>
                {/* @ts-ignore */}
                <VuiButton
                  color="primary"
                  fullWidth
                  onClick={() => {
                    setActiveModal(ActiveModal.Shopify);
                  }}
                >
                  Edit
                </VuiButton>
              </Grid>
              <Grid item xs={6}>
                {/* @ts-ignore */}
                <VuiButton
                  color="white"
                  fullWidth
                  onClick={() => {
                    window.location.href = "https://apps.shopify.com/daily-mentor";
                  }}
                >
                  Reinstall App
                </VuiButton>
              </Grid>
              <Grid item xs={12}>
                <ShopifyCollectionStatus />
              </Grid>
              <Grid item xs={12}>
                {/* @ts-ignore */}
                <VuiButton
                  color="primary"
                  fullWidth
                  onClick={() => setActiveModal(ActiveModal.InviteUser)}
                >
                  Invite subuser
                </VuiButton>
              </Grid>
              <Grid item xs={12}>
                {/* @ts-ignore */}
                <VuiButton
                  color="white"
                  fullWidth
                  onClick={handleSyncShopify}
                >
                  Sync Shopify
                </VuiButton>
              </Grid>
            </Grid>
          </VuiBox>
        </Card>
      </Grid>

      {/* Facebook Connection */}
      <Grid item xs={12} md={6} lg={3}>
        <Card>
          <VuiBox p={2}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12}>
                <AvatarStatus
                  src={getLogo("facebook")}
                  name="Facebook Connection"
                />
              </Grid>
              <Grid item xs={6}>
                {/* @ts-ignore */}
                <VuiButton
                  color="primary"
                  fullWidth
                  onClick={() => {
                    setActiveModal(ActiveModal.Facebook);
                  }}
                >
                  Edit
                </VuiButton>
              </Grid>
              <Grid item xs={6}>
                <FacebookConnect
                  title={
                    selectedMer?.facebookAccountId ? "Refresh Token" : "Connect"
                  }
                  merchant={selectedMer}
                />
              </Grid>
              {selectedMer?.facebookAccessTokenExpiry?.start && (
                <Grid item xs={12}>
                  <VuiTypography
                    variant="button"
                    color="text"
                    fontWeight="regular"
                  >
                    Access token activation
                  </VuiTypography>
                  <Demo />
                </Grid>
              )}
            </Grid>
          </VuiBox>
        </Card>
      </Grid>

      {/* Google Connection */}
      <Grid item xs={12} md={6} lg={3}>
        <Card>
          <VuiBox p={2}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12}>
                <AvatarStatus
                  src={getLogo("google")}
                  name="Google Connection"
                />
              </Grid>
              <Grid item xs={6}>
                {/* @ts-ignore */}
                <VuiButton
                  color="primary"
                  fullWidth
                  onClick={() => {
                    setActiveModal(ActiveModal.Google);
                  }}
                >
                  Edit
                </VuiButton>
              </Grid>
              <Grid item xs={6}>
                <GoogleConnect merchant={selectedMer} />
              </Grid>
            </Grid>
          </VuiBox>
        </Card>
      </Grid>
    </Grid>
  );

  const handleSyncShopify = async () => {
    const token = getToken ? await getToken() : undefined;
    if (!token) return;

    try {
      await MerchantService.syncShopifyProducts(selectedMer.id, token);
      enqueueSnackbar("Shopify sync started successfully", { variant: "success" });
    } catch (error: any) {
      enqueueSnackbar(error.response.data.message || "Failed to sync Shopify products", { variant: "error" });
    }
  };

  if (!isOwner) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <VuiBox py={3}>
          <AccessDeniedCard
            title="Access Denied"
            message="Only owners can access and manage store details of selected merchant. Please contact your administrator for access."
          />
        </VuiBox>
      </DashboardLayout>
    );
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        {
          <>
            {Object.keys(selectedMer).length !== 0 ? (
              <Stack spacing={2}>
                <ProfileInfo />
                <StoreConnections />
              </Stack>
            ) : (
              <Warning
                text="Please add an account first"
                detail="Please add a merchant to view your store details"
              />
            )}
          </>
        }
        <ShopifyStoreSettingsModal
          isOpen={activeModal === ActiveModal.Shopify}
          onClose={() => {
            setActiveModal(undefined);
          }}
        />
        <FacebookSettingsModal
          isOpen={activeModal === ActiveModal.Facebook}
          onClose={() => {
            setActiveModal(undefined);
          }}
        />
        <GoogleSettingsModal
          isOpen={activeModal === ActiveModal.Google}
          onClose={() => {
            setActiveModal(undefined);
          }}
        />
        <InviteUserModal
          isOpen={activeModal === ActiveModal.InviteUser}
          onClose={() => {
            setActiveModal(undefined);
          }}
          merchantId={selectedMer.id}
        />
      </VuiBox>
    </DashboardLayout>
  );
}

const mapStateToProps = ({ merchants }: any) => {
  const { selectedMerchant: merchant } = merchants;
  return { merchant };
};

const mapDispatchToProps = {
  pageCommonProps,
  setSelectedMerchant,
};

export default connect(mapStateToProps, mapDispatchToProps)(StoreDetails);
