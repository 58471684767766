import { type Merchant } from "types/merchant";
import { useSelector } from "react-redux";
import { useApi } from "hooks/useAPI";
import { useEffect, useState } from "react";
import { CostsService, Product } from "services/CostsService";
import { IVariant } from "types/variant";
import { Grid, Card, Stack } from "@mui/material";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  Box,
  Icon,
} from "@mui/material";
import { enqueueSnackbar, useSnackbar } from "notistack";
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  Edit as EditIcon,
  Save as SaveIcon,
  Close as CloseIcon,
} from "@mui/icons-material";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import breakpoints from "assets/theme/base/breakpoints";
import VuiInput from "components/VuiInput";
import VuiSelect from "components/VuiSelect";
import VuiButton from "components/VuiButton";
import VuiPagination from "components/VuiPagination";
import { IoSearchCircleOutline } from "react-icons/io5";
import AccessDeniedCard from "components/shared-components/AccessDeniedCard";

type SortField = "revenue" | "soldCount" | "lastSoldAt";
type SortOrder = "asc" | "desc";
const DEFAULT_PAGE_SIZE = 50;

interface RowProps {
  product: Product;
  onEdit: (variant: IVariant) => void;
  onSave: (id: string, costPrice: number, ignoreInReporting: boolean) => void;
  onCancel: () => void;
  editingKey: string;
  selectedMerchant: any;
}

const Row: React.FC<RowProps> = ({
  product,
  onEdit,
  onSave,
  onCancel,
  editingKey,
  selectedMerchant,
}) => {
  const [open, setOpen] = useState(false);
  const [costPrice, setCostPrice] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [ignoreInReporting, setIgnoreInReporting] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell style={{ minWidth: "38px" }}>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? (
              <KeyboardArrowUpIcon sx={{ color: "white !important" }} />
            ) : (
              <KeyboardArrowDownIcon sx={{ color: "white !important" }} />
            )}
          </IconButton>
        </TableCell>
        <TableCell>
          <VuiTypography variant="button" color="white">
            {product.productTitle}
          </VuiTypography>
        </TableCell>
        <TableCell>
          <VuiTypography variant="button" color="white">
            {product.lastSoldAt
              ? new Date(product.lastSoldAt).toLocaleDateString()
              : "-"}
          </VuiTypography>
        </TableCell>
        <TableCell>
          <VuiTypography variant="button" color="white">
            {product.totalRevenue
              ? `$${product.totalRevenue.toLocaleString(
                  selectedMerchant.currency,
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )}`
              : "$0.00"}
          </VuiTypography>
        </TableCell>
        <TableCell>
          <VuiTypography variant="button" color="white">
            {product.totalSold?.toLocaleString() || "0"}
          </VuiTypography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={5} style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table sx={{ tableLayout: "fixed" }} size="small">
                <TableHead sx={{ display: "contents" }} >
                  <TableRow>
                    <TableCell style={{ minWidth: "280px" }}>
                      <VuiTypography variant="caption" color="text">
                        Variant Title
                      </VuiTypography>
                    </TableCell>
                    <TableCell style={{ minWidth: "280px" }}>
                      <VuiTypography variant="caption" color="text">
                        SKU
                      </VuiTypography>
                    </TableCell>
                    <TableCell style={{ minWidth: "260px" }}>
                      <VuiTypography variant="caption" color="text">
                        Cost Price
                      </VuiTypography>
                    </TableCell>
                    <TableCell style={{ minWidth: "260px" }}>
                      <VuiTypography variant="caption" color="text">
                        Ignore In Reporting
                      </VuiTypography>
                    </TableCell>
                    <TableCell style={{ minWidth: "260px" }}>
                      <VuiTypography variant="caption" color="text">
                        Actions
                      </VuiTypography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {product.variants.map((variant) => {
                    const isEditing = variant.id === editingKey;
                    return (
                      <TableRow key={variant.id}>
                        <TableCell sx={{ minWidth: "280px" }}>
                          <VuiTypography variant="button" color="white">
                            {variant.variantTitle}
                          </VuiTypography>
                        </TableCell>
                        <TableCell sx={{ minWidth: "280px" }}>
                          <VuiTypography variant="button" color="white">
                            {variant.sku}
                          </VuiTypography>
                        </TableCell>
                        <TableCell sx={{ minWidth: "100px" }}>
                          {isEditing ? (
                            <>
                              <VuiInput
                                //@ts-ignore
                                type="number"
                                defaultValue={variant.costPrice}
                                onChange={(e: any) =>
                                  setCostPrice(e.target.value)
                                }
                                size="small"
                                InputProps={{
                                  startAdornment: "$",
                                }}
                              />
                            </>
                          ) : (
                            <VuiTypography variant="button" color="white">
                              ${variant.costPrice.toFixed(2)}
                            </VuiTypography>
                          )}
                        </TableCell>
                        <TableCell sx={{ minWidth: "100px" }}>
                          {isEditing ? (
                            <VuiSelect
                              //@ts-ignore
                              defaultValue={variant.ignoreInReporting}
                              onChange={(value: {
                                label: string;
                                value: string;
                              }) =>
                                setIgnoreInReporting(value.value === "true")
                              }
                              size="small"
                              options={[
                                { label: "Yes", value: "true" },
                                { label: "No", value: "false" },
                              ]}
                            />
                          ) : variant.ignoreInReporting ? (
                            <VuiTypography variant="button" color="white">
                              Yes
                            </VuiTypography>
                          ) : (
                            <VuiTypography variant="button" color="white">
                              No
                            </VuiTypography>
                          )}
                        </TableCell>
                        <TableCell sx={{ minWidth: "100px" }}>
                          {isEditing ? (
                            <>
                              <IconButton
                                size="small"
                                onClick={() =>
                                  onSave(
                                    variant.id,
                                    costPrice,
                                    ignoreInReporting
                                  )
                                }
                              >
                                <SaveIcon sx={{ color: "white !important" }} />
                              </IconButton>
                              <IconButton size="small" onClick={onCancel}>
                                <CloseIcon sx={{ color: "white !important" }} />
                              </IconButton>
                            </>
                          ) : (
                            <IconButton
                              size="small"
                              onClick={() => onEdit(variant)}
                              disabled={editingKey !== ""}
                            >
                              <EditIcon sx={{ color: "white !important" }} />
                            </IconButton>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const CostsAndExpenses: React.FC = () => {
  const { get, put, post } = useApi();
  const { values } = breakpoints;

  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(false);
  const [sortField, setSortField] = useState<SortField>("lastSoldAt");
  const [sortOrder, setSortOrder] = useState<SortOrder>("desc");
  const [pagination, setPagination] = useState<any>({
    current: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    total: 0,
  });
  const [searchText, setSearchText] = useState<string>("");
  const [searchActive, setSearchActive] = useState<boolean>(false);
  const [hiddenFilter, setHiddenFilter] = useState<boolean | undefined>(
    undefined
  );
  const [updateAllCost, setUpdateAllCost] = useState("");
  const [updateAllHidden, setUpdateAllHidden] = useState(false);
  const [editingKey, setEditingKey] = useState("");

  const selectedMerchant: Merchant = useSelector(
    (state: any) => state.merchants.selectedMerchant
  );

  const userRole = useSelector((state: any) => state.merchants.selectedMerchant.roles?.name);
  const isOwner = userRole === "Owner";

  const fetchVariants = async (
    page = 1,
    pageSize = DEFAULT_PAGE_SIZE,
    search: string,
    hidden: boolean | undefined
  ) => {
    if (!isOwner) return;
    if (selectedMerchant) {
      setLoading(true);
      const costsService = new CostsService(get);
      try {
        const result = await costsService.getVariants({
          merchantId: selectedMerchant.id,
          page,
          limit: pageSize,
          search,
          hidden,
          sortBy: sortField,
          sortOrder,
        });
        setProducts(result.results);
        setPagination({
          ...pagination,
          current: page,
          pageSize,
          total: result.total,
        });
      } catch (error) {
        console.error("Error fetching variants:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchVariants(
      pagination.current,
      pagination.pageSize,
      searchText,
      hiddenFilter
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMerchant]);

  if (!isOwner) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <VuiBox py={3}>
          <AccessDeniedCard
            title="Access Denied"
            message="Only owners can access and manage costs and expenses of selected merchant. Please contact your administrator for access."
          />
        </VuiBox>
      </DashboardLayout>
    );
  }

  const edit = (record: IVariant) => {
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (
    key: string,
    costPrice: number,
    ignoreInReporting: boolean
  ) => {
    console.log("save", key);
    try {
      // Update the variant in the API
      const costsService = new CostsService(put);
      await costsService.updateVariant(key, selectedMerchant.id, {
        costPrice,
        ignoreInReporting,
      });

      // Reset editing state
      setEditingKey("");

      // Reload the current page of data
      fetchVariants(
        pagination.current,
        pagination.pageSize,
        searchText,
        hiddenFilter
      );

      enqueueSnackbar("Variant updated", {
        variant: "success",
        autoHideDuration: 3000,
      });
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const handleUpdateAllCosts = async () => {
    if (selectedMerchant) {
      const costsService = new CostsService(post);
      try {
        await costsService.setVariantCostPriceWithSearch(
          selectedMerchant.id,
          searchText,
          parseFloat(updateAllCost)
        );
        fetchVariants(
          pagination.current,
          pagination.pageSize,
          searchText,
          hiddenFilter
        );
        enqueueSnackbar("Costs for filtered variants updated", {
          variant: "success",
          autoHideDuration: 3000,
        });
      } catch (error) {
        console.error("Error updating all costs:", error);
      }
    }
  };

  const handleUpdateAllHidden = async () => {
    if (selectedMerchant) {
      const costsService = new CostsService(post);
      try {
        await costsService.setHiddenWithSearch(
          selectedMerchant.id,
          searchText,
          updateAllHidden
        );
        fetchVariants(
          pagination.current,
          pagination.pageSize,
          searchText,
          hiddenFilter
        );
        enqueueSnackbar("Reporting ignore for filtered variants updated", {
          variant: "success",
          autoHideDuration: 3000,
        });
      } catch (error) {
        console.error("Error updating all costs:", error);
      }
    }
  };

  const handleTableChange = (newPagination: any, _: any, sorter: any) => {
    // Map table sort field to API sort field
    const sortFieldMap: {
      [key: string]: SortField;
    } = {
      totalRevenue: "revenue",
      totalSold: "soldCount",
      lastSoldAt: "lastSoldAt",
    };

    setSortField(sorter.field ? sortFieldMap[sorter.field] : "lastSoldAt");
    setSortOrder(
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : "desc"
    );

    fetchVariants(
      newPagination.current,
      newPagination.pageSize,
      searchText,
      hiddenFilter
    );
  };

  const handleSearch = () => {
    setSearchActive(!!searchText);
    fetchVariants(1, pagination.pageSize, searchText, hiddenFilter);
  };

  const handleHiddenFilterChange = (value: string) => {
    const hiddenValue =
      value === "true" ? true : value === "false" ? false : undefined;
    setHiddenFilter(hiddenValue);
    fetchVariants(1, pagination.pageSize, searchText, hiddenValue);
  };

  const pageSize = pagination.pageSize || DEFAULT_PAGE_SIZE;
  const currentPage = pagination.current || 1;
  const totalPages = Math.ceil((pagination.total || 0) / pageSize);
  const entriesStart = (currentPage - 1) * pageSize + 1;
  const entriesEnd = Math.min(currentPage * pageSize, pagination.total || 0);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <Grid container spacing={2}>
          <Grid item xs={24}>
            <VuiBox mb={3} p={1}>
              <VuiTypography
                variant={window.innerWidth < values.sm ? "h3" : "h2"}
                textTransform="capitalize"
                fontWeight="bold"
                color="white"
              >
                {loading && `Loading Variants`}
                {!loading && products.length === 0 && `No variants found`}
                {!loading &&
                  products.length > 0 &&
                  `${pagination.total?.toLocaleString()} variants`}
              </VuiTypography>
            </VuiBox>
          </Grid>
          <Grid item xs={24}>
            <Card>
              <VuiBox p={3}>
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={12} md={4}>
                    <VuiBox>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <VuiTypography
                          variant="caption"
                          color="text"
                          opacity={1}
                          textTransform="capitalize"
                          fontWeight="bold"
                        >
                          Search:
                        </VuiTypography>
                        <VuiInput
                          //@ts-ignore
                          placeholder="SKU, product or variant name"
                          value={searchText}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setSearchText(e.target.value)
                          }
                          onPressEnter={handleSearch}
                          fullWidth
                          prefix={<IoSearchCircleOutline />}
                        />
                      </Stack>
                    </VuiBox>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <VuiBox>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <VuiTypography
                          variant="caption"
                          color="text"
                          opacity={1}
                          textTransform="capitalize"
                          fontWeight="bold"
                          mb={1}
                        >
                          Ignore In Reporting:
                        </VuiTypography>
                        <VuiSelect
                          //@ts-ignore
                          defaultValue={{ label: "All", value: "undefined" }}
                          options={[
                            { label: "All", value: "undefined" },
                            { label: "Hidden", value: "true" },
                            { label: "Visible", value: "false" },
                          ]}
                          onChange={(value: { label: string; value: string }) =>
                            handleHiddenFilterChange(value.value)
                          }
                          fullWidth
                        />
                      </Stack>
                    </VuiBox>
                  </Grid>
                </Grid>

                {searchActive && (pagination.total ?? 0) > 1 && (
                  <Grid container spacing={2} sx={{ mt: 2 }}>
                    <Grid item xs={12}>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <VuiTypography
                          variant="caption"
                          color="text"
                          opacity={1}
                          textTransform="capitalize"
                          fontWeight="bold"
                          sx={{ minWidth: "147px" }}
                        >
                          Update All Costs:
                        </VuiTypography>
                        <VuiInput
                          //@ts-ignore
                          placeholder="Enter new cost"
                          value={updateAllCost}
                          onChange={(e: any) =>
                            setUpdateAllCost(e.target.value)
                          }
                          type="number"
                          sx={{ flex: 1, maxWidth: "300px" }}
                        />
                        {/* @ts-ignore */}
                        <VuiButton onClick={handleUpdateAllCosts}>
                          Update All
                        </VuiButton>
                      </Stack>
                    </Grid>

                    <Grid item xs={12}>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <VuiTypography
                          variant="caption"
                          color="text"
                          opacity={1}
                          textTransform="capitalize"
                          fontWeight="bold"
                          sx={{ minWidth: "147px" }}
                        >
                          Ignore all in reporting:
                        </VuiTypography>
                        <VuiSelect
                          //@ts-ignore
                          defaultValue={{ label: "All", value: "undefined" }}
                          options={[
                            { label: "Yes", value: "true" },
                            { label: "No", value: "false" },
                          ]}
                          onChange={(value: { label: string; value: string }) =>
                            setUpdateAllHidden(value.value === "true")
                          }
                          sx={{ flex: 1, maxWidth: "300px" }}
                        />
                        {/* @ts-ignore */}
                        <VuiButton onClick={handleUpdateAllHidden}>
                          Update All
                        </VuiButton>
                      </Stack>
                    </Grid>
                  </Grid>
                )}

                <TableContainer component={Paper}>
                  <Table sx={{ tableLayout: "fixed" }}>
                    <TableHead sx={{ display: "contents" }}>
                      <TableRow>
                        <TableCell style={{ minWidth: "38px" }} />
                        <TableCell style={{ minWidth: "543px" }}>
                          <VuiTypography variant="caption" color="text">
                            Product Title
                          </VuiTypography>
                        </TableCell>
                        <TableCell style={{ minWidth: "277px" }}>
                          <VuiTypography variant="caption" color="text">
                            Last Sold At
                          </VuiTypography>
                        </TableCell>
                        <TableCell style={{ minWidth: "277px" }}>
                          <VuiTypography variant="caption" color="text">
                            Total Revenue
                          </VuiTypography>
                        </TableCell>
                        <TableCell style={{ minWidth: "277px" }}>
                          <VuiTypography variant="caption" color="text">
                            Total Sold
                          </VuiTypography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {products.map((product) => (
                        <Row
                          key={product.productTitle}
                          product={product}
                          onEdit={edit}
                          onSave={save}
                          onCancel={cancel}
                          editingKey={editingKey}
                          selectedMerchant={selectedMerchant}
                        />
                      ))}
                    </TableBody>
                  </Table>

                  <VuiBox
                    display="flex"
                    flexDirection={{ xs: "column", md: "row" }}
                    justifyContent="space-between"
                    alignItems={{ xs: "flex-start", md: "center" }}
                    p={3}
                  >
                    <VuiBox mb={{ xs: 3, md: 0 }}>
                      <VuiTypography
                        variant="button"
                        color="white"
                        fontWeight="regular"
                      >
                        Showing {entriesStart} to {entriesEnd} of{" "}
                        {pagination.total} entries
                      </VuiTypography>
                    </VuiBox>
                    {totalPages > 1 && (
                      //@ts-ignore
                      <VuiPagination variant="gradient" color="info">
                        {currentPage > 1 && (
                          //@ts-ignore
                          <VuiPagination
                            item
                            onClick={() =>
                              handleTableChange(
                                { ...pagination, current: currentPage - 1 },
                                null,
                                {}
                              )
                            }
                          >
                            <Icon sx={{ fontWeight: "bold" }}>
                              chevron_left
                            </Icon>
                          </VuiPagination>
                        )}

                        {totalPages <= 6 ? (
                          [...Array(totalPages)].map((_, index) => (
                            //@ts-ignore
                            <VuiPagination
                              key={index + 1}
                              item
                              active={currentPage === index + 1}
                              onClick={() =>
                                handleTableChange(
                                  { ...pagination, current: index + 1 },
                                  null,
                                  {}
                                )
                              }
                            >
                              {index + 1}
                            </VuiPagination>
                          ))
                        ) : (
                          <VuiBox width="5rem" mx={1}>
                            <VuiInput
                              //@ts-ignore
                              inputProps={{
                                type: "number",
                                min: 1,
                                max: totalPages,
                              }}
                              value={currentPage}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                const page = parseInt(e.target.value);
                                if (page >= 1 && page <= totalPages) {
                                  handleTableChange(
                                    { ...pagination, current: page },
                                    null,
                                    {}
                                  );
                                }
                              }}
                            />
                          </VuiBox>
                        )}

                        {currentPage < totalPages && (
                          //@ts-ignore
                          <VuiPagination
                            item
                            onClick={() =>
                              handleTableChange(
                                { ...pagination, current: currentPage + 1 },
                                null,
                                {}
                              )
                            }
                          >
                            <Icon sx={{ fontWeight: "bold" }}>
                              chevron_right
                            </Icon>
                          </VuiPagination>
                        )}
                      </VuiPagination>
                    )}
                  </VuiBox>
                </TableContainer>
              </VuiBox>
            </Card>
          </Grid>
        </Grid>
      </VuiBox>
    </DashboardLayout>
  );
};

export default CostsAndExpenses;
