import axios from "../interceptors";

interface CreateInvitationRequest {
  email: string;
  merchantId: string;
  role: string;
  name: string;
}

interface CreateInvitationResponse {
  success: boolean;
  message: string;
}

interface AcceptInvitationRequest {
  invitationToken: string;
}

interface AcceptInvitationResponse {
  success: boolean;
  message: string;
}

interface IInvitationService {
  createInvitation: (data: CreateInvitationRequest, token?: string) => Promise<CreateInvitationResponse>;
  acceptInvitation: (data: AcceptInvitationRequest, token?: string) => Promise<AcceptInvitationResponse>;
}

//@ts-expect-error
const InvitationService: IInvitationService = {};

export const InvitationServiceRoutes = {
  createInvitation: "invitation/create",
  acceptInvitation: "invitation/accept",
};

InvitationService.createInvitation = async (data, token) =>
  axios.post(InvitationServiceRoutes.createInvitation, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

InvitationService.acceptInvitation = async (data, token) =>
  axios.post(`${InvitationServiceRoutes.acceptInvitation}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export default InvitationService; 