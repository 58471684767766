import { useEffect, useMemo } from "react";
import Loading from "components/shared-components/Loading";
import MerchantService from "services/MerchantService";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { useApi } from "hooks/useAPI";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { Card, Grid } from "@mui/material";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useSnackbar } from "notistack";

const ShopifyCallback = () => {
  const { getToken } = useApi();
  const searchParams = useMemo(() => {
    return new URLSearchParams(window.location.search);
  }, []);

  const { user } = useKindeAuth();
  const { enqueueSnackbar } = useSnackbar();

  const { code, hmac, shop, state, timestamp, host } = useMemo(() => {
    return {
      code: encodeURIComponent(searchParams.get("code") || ""),
      hmac: searchParams.get("hmac") || "",
      shop: searchParams.get("shop") || "",
      state: searchParams.get("state") || "",
      timestamp: searchParams.get("timestamp") || "",
      host: searchParams.get("host") || "",
    };
  }, [searchParams]);

  useEffect(() => {
    async function createMerchant() {
      const token = getToken ? await getToken() : undefined;
      try {
        const result = await MerchantService.create(
          {
            code,
            shop,
            hmac,
            state,
            host: host,
            userId: user?.id || "",
            timestamp,
          },
          token
        );
        if (result.id) {
          enqueueSnackbar("Merchant created", { variant: "success" });
          window.location.href = `${APP_PREFIX_PATH}/settings/store-connections`;
        }
      } catch (e: any) {
        if (e?.response?.data?.message === "Merchant already exists.") {
          window.location.href = `${APP_PREFIX_PATH}/settings/store-connections`;
        } else {
          enqueueSnackbar(e.message, { variant: "error" });
        }
      }
    }
    createMerchant();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, shop, state, timestamp, hmac, user]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6}>
            <Card>
              <VuiTypography
                variant="h3"
                color="white"
                fontWeight="medium"
                mb={3}
              >
                Adding your Merchant account
              </VuiTypography>
              <Loading />
            </Card>
          </Grid>
        </Grid>
      </VuiBox>
    </DashboardLayout>
  );
};

export default ShopifyCallback;
