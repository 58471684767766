import Card from "@mui/material/Card";
import VuiTypography from "components/VuiTypography";

interface AccessDeniedCardProps {
  title?: string;
  message?: string;
}

const AccessDeniedCard = ({ 
  title = "Access Denied",
  message = "Description of the denied access."
}: AccessDeniedCardProps) => {
  return (
    <Card sx={{ p: 3, backgroundColor: "rgba(0,0,0,0.2)" }}>
      <VuiTypography
        variant="h4"
        color="error"
        fontWeight="medium"
        mb={1}
      >
        {title}
      </VuiTypography>
      <VuiTypography
        variant="body1"
        color="white"
        fontWeight="regular"
      >
        {message}
      </VuiTypography>
    </Card>
  );
};

export default AccessDeniedCard; 