interface GetSubusersProps {
  merchantId: string;
  page?: number;
  limit?: number;
  sortBy?: string;
}

export interface Subuser {
  id: string;
  name: string;
  email: string;
  role: string;
  lastActive: string;
  merchantId: string;
  roleId: string;
}

interface GetSubusersResponse {
  results: Subuser[];
  page: number;
  limit: number;
  totalPages: number;
  totalResults: number;
}

type GetFunction = <T>(url: string) => Promise<T>;
type DeleteFunction = (url: string) => Promise<void>;

export class SubuserService {
  private get: GetFunction;
  private delete: DeleteFunction;

  constructor(
    getFn: GetFunction,
    deleteFn: DeleteFunction
  ) {
    this.get = getFn;
    this.delete = deleteFn;
  }

  async getSubusers(params: GetSubusersProps): Promise<GetSubusersResponse> {
    const { merchantId, page, limit, sortBy } = params;
    const queryParams = new URLSearchParams({
      merchantId,
      ...(page !== undefined && { page: page.toString() }),
      ...(limit !== undefined && { limit: limit.toString() }),
      ...(sortBy !== undefined && { sortBy }),
    }).toString();

    const response = await this.get<GetSubusersResponse>(
      `subusers?${queryParams}`
    );
    return response;
  }

  async deleteSubuser(id: string, merchantId: string): Promise<void> {
    await this.delete(`subusers/${id}/merchant/${merchantId}`);
  }
} 