import { useState } from "react";
import { useApi } from "hooks/useAPI";
import InvitationService from "services/InvitationService";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Stack,
} from "@mui/material";
import VuiButton from "components/VuiButton";
import { useSnackbar } from "notistack";
import FormField from "./components/FormField";

interface InviteUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  merchantId: string;
}

export function InviteUserModal({
  isOpen,
  onClose,
  merchantId,
}: InviteUserModalProps) {
  const { getToken } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const [formValues, setFormValues] = useState({
    email: "",
    name: "",
    role: "Viewer" // Default role (for forseable future)
  });

  const handleInputChange = (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const handleInvite = async () => {
    const token = getToken ? await getToken() : undefined;
    
    try {
      await InvitationService.createInvitation({
        email: formValues.email,
        name: formValues.name,
        merchantId: merchantId,
        role: formValues.role
      }, token);
      
      // Reset form values after successful invitation
      setFormValues({
        email: "",
        name: "",
        role: "Viewer"
      });

      enqueueSnackbar("Invitation sent successfully!", { variant: "success" });
      onClose();
    } catch (error: any) {
      enqueueSnackbar(error.response.data.message || "Failed to send invitation", { variant: "error" });
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          backgroundColor: "rgba(0, 0, 0, 0.87)",
          color: "white",
        },
      }}
    >
      <DialogTitle sx={{ color: "white !important" }}>
        Invite User
      </DialogTitle>
      <DialogContent>
        <Grid item xs={12} sm={6}>
          <Stack direction="column" spacing={2} sx={{ mt: 2 }}>
            <FormField
              label="Name"
              placeholder="Enter user's name"
              onChange={handleInputChange("name")}
              value={formValues.name}
            />
            <FormField
              label="Email"
              placeholder="Enter user's email"
              type="text"
              onChange={handleInputChange("email")}
              value={formValues.email}
            />
          </Stack>
        </Grid>
      </DialogContent>
      <DialogActions>
        {/* @ts-ignore */}
        <VuiButton variant="outlined" onClick={onClose}>
          Cancel
        </VuiButton>
        {/* @ts-ignore */}
        <VuiButton 
          variant="contained" 
          onClick={handleInvite}
          disabled={!formValues.email || !formValues.name}
        >
          Send Invitation
        </VuiButton>
      </DialogActions>
    </Dialog>
  );
} 